var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CRow",
    [
      _c(
        "CCol",
        { attrs: { sm: "12" } },
        [
          _c(
            "CCard",
            [
              _c(
                "CCardHeader",
                [
                  _c("CIcon", { attrs: { name: "cil-star" } }),
                  _c("h5", { staticClass: "d-inline ml-2" }, [
                    _vm._v("Reviews"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "card-header-actions" },
                    [
                      _vm.restaurantId
                        ? _c(
                            "CButton",
                            {
                              staticClass: "ml-2",
                              attrs: {
                                color: "link",
                                to: {
                                  name: "View Restaurant",
                                  params: { id: _vm.restaurantId },
                                },
                              },
                            },
                            [
                              _c("CIcon", {
                                staticClass: "align-bottom",
                                attrs: { name: "cil-arrow-thick-left" },
                              }),
                              _vm._v(" Back To Restaurant "),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("BackendTable"),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }