var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCardBody",
    [
      _c("alert-section", {
        attrs: {
          successAlertMessage: _vm.successAlertMessage,
          dismissSecs: _vm.dismissSecs,
          dismissSuccessAlert: _vm.dismissSuccessAlert,
          errorAlertMessage: _vm.errorAlertMessage,
          showErrorAlert: _vm.showErrorAlert,
        },
      }),
      _c(
        "CCard",
        { staticClass: "filters", attrs: { "accent-color": "warning" } },
        [
          _c(
            "CCardHeader",
            {
              staticClass: "text-warning shadow-none card-header",
              attrs: { role: "button" },
              on: { click: _vm.resetFilters },
            },
            [
              _c(
                "strong",
                { staticClass: "m-0" },
                [
                  _c("CIcon", {
                    attrs: {
                      name: `cil-filter${
                        _vm.$store.state.filterShow ? "-x" : ""
                      }`,
                    },
                  }),
                  _vm._v(" Filters"),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "card-header-actions" },
                [
                  _c("CIcon", {
                    attrs: {
                      name: `cil-chevron-${
                        _vm.$store.state.filterShow ? "bottom" : "top"
                      }`,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "CCollapse",
            { attrs: { show: _vm.$store.state.filterShow } },
            [
              _c("CCardBody", { staticClass: "p-2" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-xl mb-2 mb-xl-0" },
                    [
                      _c("v-select", {
                        staticClass: "v-select-filter",
                        attrs: {
                          placeholder: "Select restaurant..",
                          options: _vm.restaurantNames,
                          reduce: (c) => c.value,
                          disabled: !!_vm.restaurantId,
                          multiple: "",
                        },
                        on: { input: _vm.getAllItems },
                        model: {
                          value: _vm.selectedRestaurants,
                          callback: function ($$v) {
                            _vm.selectedRestaurants = $$v
                          },
                          expression: "selectedRestaurants",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xl mb-2 mb-xl-0" },
                    [
                      _c("CInput", {
                        staticClass: "mb-0",
                        attrs: { type: "search", placeholder: "Search.." },
                        on: { input: _vm.searchFilter },
                        model: {
                          value: _vm.search,
                          callback: function ($$v) {
                            _vm.search = $$v
                          },
                          expression: "search",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("CDataTable", {
        attrs: {
          striped: "",
          hover: "",
          items: _vm.loadedItems,
          fields: _vm.fields,
          sorter: { external: true, resetable: true },
          "column-filter": { external: true, lazy: true },
          itemsPerPageSelect: {
            external: true,
            values: [5, 15, 25, 50, 100, 250, 500],
          },
          "items-per-page": _vm.itemsPerPage,
          loading: _vm.loading,
          noItemsView: {
            noResults: "No filtering results are available!",
            noItems: "No reviews found!",
          },
        },
        on: {
          "update:itemsPerPage": function ($event) {
            _vm.itemsPerPage = $event
          },
          "update:items-per-page": function ($event) {
            _vm.itemsPerPage = $event
          },
          "update:sorter-value": _vm.sorterValue,
          "pagination-change": _vm.paginationChange,
        },
        scopedSlots: _vm._u([
          {
            key: "user_orders",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  { staticClass: "text-center" },
                  [
                    _c(
                      "CLink",
                      {
                        attrs: {
                          to: {
                            name: "Order Details",
                            params: { id: item.rrroid },
                          },
                          target: "_blank",
                        },
                      },
                      [_c("CIcon", { attrs: { name: "cil-basket" } })],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "reviewer",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  [
                    item.order
                      ? [
                          _vm._v(" " + _vm._s(item.user_name) + " "),
                          _c("div", { staticClass: "small" }, [
                            _vm._v(_vm._s(item.order.user.email)),
                          ]),
                        ]
                      : _c("span", [_vm._v("Deleted Order !")]),
                  ],
                  2
                ),
              ]
            },
          },
          {
            key: "anonymous",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  [
                    _c("CSwitch", {
                      attrs: {
                        checked: item.anonymous,
                        labelOn: "YES",
                        labelOff: "NO",
                        color: "success",
                        shape: "pill",
                      },
                      on: {
                        "update:checked": [
                          function ($event) {
                            return _vm.$set(item, "anonymous", $event)
                          },
                          () => _vm.updateAnonymous(item),
                        ],
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "restaurant_name",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  [
                    _c(
                      "CLink",
                      {
                        attrs: {
                          to: {
                            name: "View Restaurant",
                            params: { id: item.restaurant_id },
                          },
                          target: "_blank",
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(item.restaurant.restaurant_name) + " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "review_text",
            fn: function ({ item }) {
              return [
                _c("td", { staticStyle: { "overflow-wrap": "anywhere" } }, [
                  _vm._v(" " + _vm._s(item.review_text) + " "),
                ]),
              ]
            },
          },
          {
            key: "actions",
            fn: function ({ item, index }) {
              return [
                _c(
                  "td",
                  { staticClass: "text-center" },
                  [
                    item.eol == 0
                      ? _c(
                          "CButton",
                          {
                            attrs: {
                              size: "sm",
                              color: "danger",
                              variant: "ghost",
                              shape: "pill",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteItem(item, index)
                              },
                            },
                          },
                          [_c("CIcon", { attrs: { name: "cil-trash" } })],
                          1
                        )
                      : [
                          _c("CIcon", {
                            staticClass: "text-danger",
                            attrs: { name: "cil-trash" },
                          }),
                        ],
                  ],
                  2
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "CCard",
        { staticClass: "actions sticky-bottom" },
        [
          _c("CCardBody", { staticClass: "p-2" }, [
            _c(
              "div",
              {
                staticClass: "d-flex flex-wrap align-items-center",
                staticStyle: { gap: "0.75rem" },
              },
              [
                _c("div", [
                  _c("h5", { staticClass: "mt-1" }, [
                    _vm._v("Total: "),
                    _c(
                      "span",
                      { staticClass: "d-inline count bg-primary pb-1" },
                      [_vm._v(_vm._s(_vm.total))]
                    ),
                  ]),
                ]),
                _vm.pages > 1
                  ? _c(
                      "div",
                      { staticClass: "ml-auto" },
                      [
                        _c("CPagination", {
                          attrs: {
                            align: "center",
                            dots: false,
                            pages: _vm.pages,
                            "active-page": _vm.activePage,
                          },
                          on: {
                            "update:activePage": function ($event) {
                              _vm.activePage = $event
                            },
                            "update:active-page": function ($event) {
                              _vm.activePage = $event
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }